h1 {
  margin-top: 60px;
  margin-bottom: 40px;
  padding:0px 5px;
  font-size: 50px;
  color: rgb(184, 165, 122);
}

.makeStyles-paper-16, .makeStyles-paper2-3 {
  direction: initial !important;
}

@media screen and (max-width: 640px) {
  h1 {
    margin-top: 10px;
    margin-bottom: 40px;
    font-size: 50px;
    color: rgb(184, 165, 122);
  }
}

.equipe {
  padding-top: 90px;
  height:100%;
  display: flex;
  text-align: center;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item {
  opacity: 0;
}
.item0 {
  animation: translate-in 1s cubic-bezier(.44,.96,.86,.99) forwards;
}
.item1 {
  animation: translate-in 1s 0.2s cubic-bezier(.44,.96,.86,.99) forwards;
}
.item2 {
  animation: translate-in 1s 0.4s cubic-bezier(.44,.96,.86,.99) forwards;
}
.item3 {
  animation: translate-in 1s 0.6s cubic-bezier(.44,.96,.86,.99) forwards;
}
.item4 {
  animation: translate-in 1s 0.8s cubic-bezier(.44,.96,.86,.99) forwards;
}
.item5 {
  animation: translate-in 1s 1s cubic-bezier(.44,.96,.86,.99) forwards;
}
.item6 {
  animation: translate-in 1s 1s cubic-bezier(.44,.96,.86,.99) forwards;
}

.item7 {
  animation: translate-in 1s 1s cubic-bezier(.44,.96,.86,.99) forwards;
}


.grid_tile {
  opacity: 0;
}


.MuiGridListTileBar-title {
  font-size: 25px !important;
  margin-top:20px;
  font-weight: normal;
  white-space: normal !important;
  color:rgb(233, 206, 144);
  padding: 5px 5px;
  background:rgb(0,0,0,0.6);
}

.MuiGridListTileBar-root {
  height:fit-content !important;
}

.grid_tile:nth-child(1) {
  animation: translate-in 1s cubic-bezier(.44,.96,.86,.99) forwards;
}
.grid_tile:nth-child(2) {
  animation: translate-in 1s 0.2s cubic-bezier(.44,.96,.86,.99) forwards;
}
.grid_tile:nth-child(3) {
  animation: translate-in 1s 0.4s cubic-bezier(.44,.96,.86,.99) forwards;
}
.grid_tile:nth-child(4) {
  animation: translate-in 1s 0.6s cubic-bezier(.44,.96,.86,.99) forwards;
}
.grid_tile:nth-child(5) {
  animation: translate-in 1s 0.8s cubic-bezier(.44,.96,.86,.99) forwards;
}
.grid_tile:nth-child(6) {
  animation: translate-in 1s 1s cubic-bezier(.44,.96,.86,.99) forwards;
}
.grid_tile:nth-child(7) {
  animation: translate-in 1s 1.2s cubic-bezier(.44,.96,.86,.99) forwards;
}

@keyframes translate-in {
  0% {
      transform: translateY(-100px);
      opacity: 0;
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
  }
}
