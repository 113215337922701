.membre {
    display:flex;
    flex-direction: column;
    width:330px;
    height:475px;
    padding:10px;
    text-align: center;
    background:  rgb(42, 42, 41,1);/*rgb(0,0,0,0.7); /*#45512f;*/
    border: rgb(0,0,0,0.2) 1px solid;
    color:#f2d6a2;
    margin:20px;
    align-items: center;
    border-radius: 5px;
    transition: 0.4s;
}

.membre_scramble {
    width:310px !important;
    height:35px;
    overflow: hidden;
}

.membre_container {
    width: 100%;
    height:390px;
    border-radius: 5px;
    overflow: hidden;
    display:flex;
    justify-content: center;
}

@keyframes fall-in {
    0% {
        transform:translateY(-20px)
    }
    100% {
        transform:translateY(0px)

    }
}

.membre:hover {
    cursor: pointer;
    transform:scale(1.05);
}

.membre_photo {
    width:100%;
    object-fit: contain;
    border-radius: 5px;
    transition: 2s;
    transform: scale(1.2);
}

.membre:hover .membre_photo {
    transform:scale(1.4)
}

.membre_scramble {
    font-size: 25px;
} 

.membre_role {
    margin:0;
}