.acc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.acc_part3 {
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
    min-height: 120vh;
    width:100%;
    background: rgba(184,165,122,1);
    overflow:hidden;
}

.acc_part3 h1 {
  color:rgba(47,88,58,1);
  text-align: center;

}

.sep {
  padding: 0px 0px;
    background-image: url("../assets/band.jpg");
    height:50px;
    width:100%;
}

.acc_logo {
  object-fit: contain;
  max-width: 900px;
  width:40%;
  animation: translate-in 1s cubic-bezier(.44,.96,.86,.99) forwards;
}

@keyframes translate-in {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.acc_frame {
  max-width: 900px;
  height: 100%;
  object-fit: contain;
}

.acc_part1 {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-height: 100vh;
  margin-top: 30px;
}

.secret {
  width:100%;
}
.secret:hover {
  cursor: default;
}

.acc_part1Text {
}

.acc_part1Text h1 {
    opacity: 0;
  margin: 20px 0;
  animation: translate-in 1s 0.8s cubic-bezier(.44,.96,.86,.99) forwards;

}

.acc_choices {
  display: flex;
  width: 400px;
}

.choice {
  flex: 2;
  text-align: center;
  background: rgb(42, 42, 41);
  margin: 0px 5px;
  height: 50px;
  line-height: 50px;
  color: rgb(184, 165, 122);
  border-radius: 8px;
  transition: 0.4s;
  opacity:0;

}

.choice:nth-child(2) {
    animation: translate-in 1s 1.2s cubic-bezier(.44,.96,.86,.99) forwards;

}

.choice:nth-child(1) {
    animation: translate-in 1s 1.1s cubic-bezier(.44,.96,.86,.99) forwards;
}

.choice:hover {
  text-decoration: none;
  color: rgb(184, 165, 122);
  flex: 3;
}

.acc_container {
  width: 90%;
  height: fit-content;
  max-width: 900px;
}

@media (max-width: 1030px) {

  .acc_pres {
    font-size: 40px;
    margin:0;
    margin-bottom: 10px;
  }
  .acc_part1,
  .acc_part1Text {
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .acc_part1 {

    min-height: 90vh;
    margin-top: 30px;
  }


  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 80%;
    height: 0;
  }
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}



.acc_part2, .acc_part5 {
  background: linear-gradient(0deg, rgba(184,165,122,1) 20%, rgba(34,66,53,1) 65%);
  min-height: 300px;
  position: relative;
  display:flex;
  width:100%;
  align-items: center;
  justify-content: space-between;
}

.acc_part5 {
  background: linear-gradient(0deg, rgba(34,66,53,1) 20%, rgba(184,165,122,1) 65%);
}


.footer {
  font-size: 25px;
  width:100%;
  color: rgba(184,165,122,1);
  height:100%;
  text-align: center;
  display : flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

}

.mineSweep {
  width:70%;
  text-align: center;
  margin:5px 0;
  margin-bottom: 30px;
}

.acc_partBomb {
  width:100%;
  justify-content: center;
  align-items: center;
  display:flex;
  flex-direction: column;
  min-height: 90vh;
}


@media (max-width: 1080px) {

  .footer {
    height:100%;

  }
}

@media (max-width: 1000px) {

  .mineS {
    width:90% !important;
  }

  .sep {
    margin-top : 20px;
  }

  .mineSweep {
    width:80%;
    text-align: center;
    margin:5px 0;
    margin-bottom: 30px;
  }
  
  .acc_partBomb {
    width:100%;
    justify-content: center;
    align-items: center;
    display:flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .sep2 {
    margin-top : 0px;

  }

  .acc_part4 {
    height:200px !important;

  }

.acc_part2 {
  background: linear-gradient(0deg, rgba(184,165,122,1) 40%, rgba(34,66,53,1) 60%);
}

.acc_part5 {
  background: linear-gradient(0deg, rgba(34,66,53,1) 40%, rgba(184,165,122,1) 60%);
}

  .acc_logo {
    width: 80%;
    margin-top: 50px;
  }

  .acc_part3 {
    padding: 60px 0;
    min-height: 600px;
  }

  .choice {
      width:80%;
      margin : 5px 0;
  }

  .choice:hover {
    text-decoration: none;
    color: rgb(184, 165, 122);
    flex: 2;
  }

  .acc_part1Text h1 {
    margin:5px 0;
  }

  .acc_part1Text {
    margin-top: 50px;
  }

  .acc_part1Text h1 {
    font-size: 30px;
  }
  .acc_part1 {
    margin-top: 30px;
    display: block;
  }
  
  .acc_choices {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }
}









.acc_part4 {
  background: rgb(42, 42, 41);
  height: 100px;
  position: relative;
  display:flex;
  width:100%;
  align-items: center;
  justify-content: space-between;
}

.footer a {
  color: rgba(184,165,122,1);
  margin : 0 40px;
}

.footer a:hover {
  color: rgba(184,165,122,1);
  text-decoration: underline;
}




@keyframes marquee {
  0% {
      transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
      transform: translate3d(var(--move-final), 0, 0);
  }
}

.gun2,.gun4,.gun6 {
  transform:rotateY(180deg);
}

