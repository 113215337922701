header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  background: rgb(42, 42, 41);
  color: rgb(184, 165, 122);
  justify-content: space-between;
  align-items: center;
  transition: 0.6s;
  padding: 30px 100px;
  z-index: 1;

}

.react-switch {
  margin-right: 15px;
}

ul {
  padding:0;
}

header .logo {
  position: relative;

  font-weight: 700;
  text-decoration: none;
  font-size: 2em;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: 0.6s;
  background: linear-gradient(90deg, rgba(47,88,58,1) 55%, rgba(184,165,122,1) 50%, rgba(184,165,122,1) 60%, rgba(47,88,58,1) 60%, rgba(47,88,58,1) 69%, rgba(184,165,122,1) 70%, rgba(184,165,122,1) 88%, rgba(47,88,58,1) 87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sticky {
  padding: 5px 100px;
}



header ul {

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
}

header ul li {
  position: relative;
  list-style: none;
}

header ul li a {
  
  color: rgb(184, 165, 122);
  position: relative;
  width: 150px;
  margin: 0 15px;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 500px;
  transition: 0.6s;
  font-size: 23px;
}

.toggle-button {
  position: relative;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  border-radius: 10px;
}

.div_switch {
  display:flex;
  align-items: center;
}

li a:hover {
  color: rgb(184, 165, 122);
    text-decoration: none;
    letter-spacing: 2px;
    font-weight: 500px;
}

@media (max-width: 1255px) {
  header,
  .sticky {
    padding: 5px 20px;
    
  }


  .active{
    flex-wrap: wrap;
  }

  header .toggle-button .bar {
    background-color:  rgb(184, 165, 122)
    ;
  }

  header ul li a,
  header .logo {
    color: rgb(184, 165, 122);

  }
  

  .toggle-button {
    display: flex;
    width:30px;
  }

  .navbar-links {
    height:0px;
    overflow: hidden;
    width: 100%;
    transition: 1s;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }

  .navbar-links ul li {
    text-align: center;
  }

  .navbar-links ul li a {
    padding: 0.5rem 1rem;
  }

  .navbar-links.active {
    height:100px;
    animation : show 1s ease-in-out forwards;
  }

  @keyframes show {
      0% {
        height:0px;
        margin: 0px 0;
      }
      100% {
        margin: 20px 0;
        height:165px;
      }
  }
}
