body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    scrollbar-width: thin;          /* "auto" or "thin"  */
    scrollbar-color: rgb(42, 42, 41) rgb(42, 42, 41,0.4);   /* scroll thumb & track */
  
}

*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: rgb(42, 42, 41,0.4);
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(42, 42, 41);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
