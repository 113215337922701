.theme {
    min-height: 10vh;
    padding-top: 150px;
    background: rgba(184,165,122,1);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
}

.sem {
    margin:20px 0;
    padding: 13px;
    color : rgb(184, 165, 122);
    border-radius: 6px;
    background :rgb(42, 42, 41);
    transform:scale(1);
    transition:0.2;
}

.sem:hover {
    cursor: pointer;
}

.sem:active {
    transform:scale(1.05);
}

.pipo {
    width:90%;
    margin: 70px 0;
    max-width: 200px;
    object-fit: contain;
    transform:scale(1);
    transition:0.3s;
}

.embauche {
    transform:scale(1);
    transition:0.3s;
}

.embauche p {
    margin:0;
}

.pipo:hover, .embauche:hover {
    cursor: pointer;
}

.pipo:active, .embauche:active {
    transform:scale(1.1);
}

.titre {
    margin:5px 0;
    text-align: center;
    color : rgb(42, 42, 41);
    max-width: 90%;
}

.embauche {
    display:flex;
    align-items: center;
    width:90%;
    justify-content: center;
    flex-wrap: wrap;
    margin:10px 0;
}
.embauche p {
    text-align: center;
    margin-left: 15px;
}
.icone {

    height:100px;
    object-fit: contain;
}

.shop {
    text-align: center;
    color : rgb(42, 42, 41);
}