/* Flashlight Overlay 
.newRoot {
  cursor: none;
  --cursorX: 50vw;
  --cursorY: 50vh;
}


.newRoot:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  background: radial-gradient(
    circle 10vmax at var(--cursorX) var(--cursorY),
    rgba(0,0,0,0.1) 0%,
    rgba(0,0,0,.5) 60%,
    rgba(0,0,0,0.9) 100%
  )
}*/

.sousTitre {
  color : rgba(184,165,122,1);
  text-decoration: underline;
  margin-top: 25px;
}

.sousProgramme {
  width:90%;
  max-width: 800px;
  color : rgba(184,165,122,1);
}