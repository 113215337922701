.App {
  min-height: 110vh;
  z-index: -3;
}



.bg {
  top:0;
  left:0;
  position: fixed;
  z-index: -5;
  background: #224235;
  height:100vh;
  width:100vw;
}

.accueil {
  height:150vh;
  width:100%;
  z-index: -2;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color: #2e594a;
  
}

/* Flashlight Overlay */
/*:root {
  cursor: none;
  --cursorX: 50vw;
  --cursorY: 50vh;
}

:root:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  pointer-events: none;
  background: radial-gradient(
    circle 10vmax at var(--cursorX) var(--cursorY),
    rgba(0,0,0,0.1) 0%,
    rgba(0,0,0,.5) 60%,
    rgba(0,0,0,0.9) 100%
  )
}*/

